var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineComponent, onMounted, ref } from 'vue';
import NewsCollection from '@/composables/collections/NewsCollection';
export default defineComponent({
    name: 'News',
    setup() {
        const loading = ref(true);
        const news = new NewsCollection();
        const mainNews = ref(null);
        const date = new Date();
        const currentYear = ref(date.getFullYear());
        const currentMonth = ref(date.getMonth() + 1);
        const currentTxtMonth = ref(date.toLocaleString('default', { month: 'long' }));
        const getNewsMonth = (year, month) => __awaiter(this, void 0, void 0, function* () {
            loading.value = true;
            yield news.getNews(year, month);
            mainNews.value = news.getMainNews();
            loading.value = false;
            currentTxtMonth.value = new Date(year, month - 1).toLocaleString('default', {
                month: 'long',
            });
        });
        onMounted(() => __awaiter(this, void 0, void 0, function* () {
            yield getNewsMonth(currentYear.value, currentMonth.value);
        }));
        const prevMonth = () => __awaiter(this, void 0, void 0, function* () {
            currentMonth.value--;
            if (currentMonth.value < 1) {
                currentMonth.value = 12;
                currentYear.value--;
            }
            yield getNewsMonth(currentYear.value, currentMonth.value);
        });
        const nextMonth = () => __awaiter(this, void 0, void 0, function* () {
            currentMonth.value++;
            if (currentMonth.value > 12) {
                currentMonth.value = 1;
                currentYear.value++;
            }
            yield getNewsMonth(currentYear.value, currentMonth.value);
        });
        return { loading, news, currentYear, currentTxtMonth, mainNews, prevMonth, nextMonth };
    },
});
