import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { id: "container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Header = _resolveComponent("Header");
    const _component_router_view = _resolveComponent("router-view");
    const _component_Footer = _resolveComponent("Footer");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Header),
        _createVNode(_component_router_view),
        _createVNode(_component_Footer)
    ]));
}
