var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ref } from '@vue/runtime-core';
class SportCollection {
    constructor() {
        this.count = ref(0);
        this.items = ref(null);
        this._url = process.env.API + '/v1/sports/';
    }
    getSports() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const req = yield fetch(this._url);
                const res = yield req.json();
                this.count.value = res.count;
                this.items.value = res.collection;
            }
            catch (error) { }
        });
    }
    getSection(subcategory) {
        if (this.items.value) {
            return this.items.value[subcategory];
        }
        return [];
    }
}
export default SportCollection;
