var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineComponent, onMounted, ref } from 'vue';
import Carrousel from '@/components/Carrousel.vue';
import Banner from '@/components/Banner.vue';
import IndexBannerCollection from '@/composables/images/IndexBannerCollection';
import PartnertCollection from '@/composables/images/PartnertCollection';
const CalendarDate = require('calendar-dates');
export default defineComponent({
    components: { Carrousel, Banner },
    name: 'Index',
    setup() {
        const bannerImages = new IndexBannerCollection();
        const partnerts = new PartnertCollection();
        const calendarDate = new CalendarDate();
        const weeks = ref([]);
        const date = ref(new Date());
        const currentTxtMonth = ref(date.value.toLocaleString('default', { month: 'long' }));
        const goToCalendar = () => {
            const calendar = document.getElementById('calendar');
            calendar.scrollIntoView({ behavior: 'smooth' });
        };
        const getMonth = (y, m) => __awaiter(this, void 0, void 0, function* () {
            date.value = new Date(y, m, 1);
            currentTxtMonth.value = date.value.toLocaleString('default', { month: 'long' });
            const dates = yield calendarDate.getMatrix(date.value);
            dates.forEach((w) => {
                weeks.value.push(w);
            });
        });
        const getEvents = () => __awaiter(this, void 0, void 0, function* () {
            const month = date.value.getMonth() + 1;
            const year = date.value.getFullYear();
            const req = yield fetch(process.env.API + '/v1/calendar/' + year + '/' + month + '/');
            const res = yield req.json();
            if (res.days && res.days.count) {
                res.days.collection.forEach((day) => {
                    const el = document.querySelector('[data-iso="' + day.iso_day + '"]');
                    if (el) {
                        el.classList.add('event');
                        const eventsEle = el.querySelector('.events');
                        if (eventsEle) {
                            eventsEle.innerHTML += '<div class="event">' + day.title + '</div>';
                        }
                    }
                });
            }
        });
        const nextMonth = () => __awaiter(this, void 0, void 0, function* () {
            weeks.value = [];
            yield getMonth(date.value.getFullYear(), date.value.getMonth() + 1);
            yield getEvents();
        });
        const prevMonth = () => __awaiter(this, void 0, void 0, function* () {
            weeks.value = [];
            yield getMonth(date.value.getFullYear(), date.value.getMonth() - 1);
            yield getEvents();
        });
        onMounted(() => __awaiter(this, void 0, void 0, function* () {
            yield bannerImages.getImages();
            yield partnerts.getCountries();
            yield getMonth(date.value.getFullYear(), date.value.getMonth());
            yield getEvents();
        }));
        return {
            //Methods
            goToCalendar,
            nextMonth,
            prevMonth,
            //Request
            bannerImages,
            partnerts,
            weeks,
            date,
            currentTxtMonth,
        };
    },
});
