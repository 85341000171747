import { defineComponent, watch, ref, onMounted } from '@vue/runtime-core';
export default defineComponent({
    name: 'Carrousel',
    props: {
        title: {
            type: String,
            required: true,
        },
        schools: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    setup(props) {
        const title = props.title;
        const schools = ref(props.schools);
        const list = ref(null);
        const currentPostition = ref(0);
        const currentClick = ref(0);
        const listWidth = ref(0);
        const itemWidth = 200;
        const flexGap = 15;
        watch(() => props.schools, (f, s) => {
            schools.value = f;
        });
        onMounted(() => {
            if (list.value) {
                listWidth.value = list.value.clientWidth;
            }
        });
        const moveRight = () => {
            const itemsOnScreen = Math.floor(listWidth.value / itemWidth);
            const maxClick = Math.floor(schools.value.length / itemsOnScreen);
            if (list.value && maxClick > currentClick.value) {
                currentPostition.value += itemsOnScreen * itemWidth + itemsOnScreen * flexGap;
                currentClick.value++;
                list.value.style.transform = `translateX(-${currentPostition.value}px)`;
            }
        };
        const moveLeft = () => {
            const itemsOnScreen = Math.floor(listWidth.value / itemWidth);
            if (list.value && currentPostition.value > 0) {
                currentClick.value--;
                currentPostition.value -= itemsOnScreen * 200 + itemsOnScreen * flexGap;
                list.value.style.transform = `translateX(-${currentPostition.value}px)`;
            }
        };
        return { title, schools, list, moveRight, moveLeft };
    },
});
