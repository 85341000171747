var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
class NewsCollection {
    constructor() {
        this.count = 0;
        this.items = [];
        this._url = process.env.API + '/v1/news/';
    }
    getNews(year = 0, month = 0) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const url = this._url + (year ? year + '/' : '') + (month ? month + '/' : '');
                const req = yield fetch(url);
                const res = yield req.json();
                this.count = res.count;
                this.items = res.collection;
            }
            catch (error) { }
        });
    }
    getMainNews() {
        let toReturn = null;
        if (this.count > 0) {
            const date = new Date();
            const year = String(date.getFullYear());
            let month = String(date.getMonth() + 1);
            let day = String(date.getDate());
            day = day.length === 1 ? '0' + day : day;
            month = month.length === 1 ? '0' + month : month;
            toReturn = this.items.find(item => {
                if (year + '-' + month + '-' + day <= item.date) {
                    return item;
                }
            });
            return toReturn ? toReturn : this.items[0];
        }
        return toReturn;
    }
}
export default NewsCollection;
