import { defineComponent, ref } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
export default defineComponent({
    name: 'Header',
    setup() {
        const menuEle = ref(null);
        const router = useRouter();
        const toggleMenu = () => {
            if (menuEle.value) {
                menuEle.value.classList.toggle('active');
            }
        };
        return {
            menuEle,
            toggleMenu,
        };
    },
});
