import { createRouter, createWebHistory } from 'vue-router';
import Index from '@/views/Index.vue';
import Faith from '@/views/Faith.vue';
import About from '@/views/About.vue';
import News from '@/views/News.vue';
import Admission from '@/views/Admission.vue';
import Academics from '@/views/Academics.vue';
import Athletics from '@/views/Athletics.vue';
import Contact from '@/views/Contact.vue';
import Donate from '@/views/Donate.vue';
import PhotoGallery from '@/views/PhotoGallery.vue';
import Gallery from '@/views/Gallery.vue';
const routes = [
    {
        path: '/',
        name: 'Index',
        // component: () => import(/* webpackChunkName: "index-view" */ '../views/Index.vue'),
        component: Index,
        meta: { auth: false },
        beforeEnter: (_to, _from, next) => {
            //TODO: set title and meta tags
            next();
        },
    },
    {
        path: '/faith',
        name: 'Faith',
        component: Faith,
        beforeEnter: (_to, _from, next) => {
            //TODO: set title and meta tags
            next();
        },
    },
    {
        path: '/news',
        name: 'News',
        component: News,
        beforeEnter: (_to, _from, next) => {
            //TODO: set title and meta tags
            next();
        },
    },
    {
        path: '/admission',
        name: 'Admission',
        component: Admission,
        beforeEnter: (_to, _from, next) => {
            //TODO: set title and meta tags
            next();
        },
    },
    {
        path: '/academics',
        name: 'Academics',
        component: Academics,
        beforeEnter: (_to, _from, next) => {
            //TODO: set title and meta tags
            next();
        },
    },
    {
        path: '/about/:subpage?',
        name: 'About',
        component: About,
        beforeEnter: (_to, _from, next) => {
            //TODO: set title and meta tags
            next();
        },
    },
    {
        path: '/athletics/:subpage?',
        name: 'Athletics',
        component: Athletics,
        beforeEnter: (_to, _from, next) => {
            //TODO: set title asnd meta tags
            next();
        },
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact,
        beforeEnter: (_to, _from, next) => {
            //TODO: set title asnd meta tags
            next();
        },
    },
    {
        path: '/donate',
        name: 'Donate',
        component: Donate,
        beforeEnter: (_to, _from, next) => {
            //TODO: set title asnd meta tags
            next();
        },
    },
    {
        path: '/galleries',
        name: 'PhotoGallery',
        component: PhotoGallery,
        beforeEnter: (_to, _from, next) => {
            //TODO: set title asnd meta tags
            next();
        },
    },
    {
        path: '/gallery/:id',
        name: 'Gallery',
        component: Gallery,
        beforeEnter: (_to, _from, next) => {
            //TODO: set title asnd meta tags
            next();
        },
    },
];
const router = createRouter({
    history: createWebHistory(),
    routes,
});
router.beforeEach((to, from) => {
    var _a;
    const nav = document.querySelector('#navMenu');
    if (nav) {
        nav.classList.remove('active');
    }
    if (to.name != from.name)
        (_a = document.getElementById('app')) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
});
export default router;
