var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineComponent, reactive, ref } from 'vue';
export default defineComponent({
    name: 'Admissions',
    setup() {
        const formState = ref('open');
        const shadowDayFields = reactive({
            shadow_date: true,
            name: '',
            email: '',
            phone: '',
            date: '',
        });
        const sendShadowDay = () => __awaiter(this, void 0, void 0, function* () {
            formState.value = 'sending';
            const req = yield fetch(process.env.API + '/v1/emails/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(shadowDayFields),
            });
            const res = yield req.json();
            console.log(res);
        });
        return {
            sendShadowDay,
            formState,
            shadowDayFields,
        };
    },
});
