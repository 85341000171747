import { onUnmounted, defineComponent, ref, watch, onMounted } from 'vue';
export default defineComponent({
    name: 'Banner',
    props: {
        cssId: {
            type: String,
            default: 'banner',
        },
        delay: {
            type: Number,
            default: 4000,
        },
        images: {
            // type: ImageType,
            default: () => [],
        },
    },
    setup(props) {
        const images = ref(props.images);
        const eleId = props.cssId;
        const currentBannerSlide = ref(0);
        const interval = ref(null);
        const delay = props.delay;
        const nextSlide = () => {
            currentBannerSlide.value = (currentBannerSlide.value + 1) % images.value.length;
        };
        watch(() => props.images, (f, s) => {
            if (f.length > 0) {
                images.value = f;
                interval.value = window.setInterval(nextSlide, delay);
            }
        });
        onMounted(() => {
            interval.value = window.setInterval(nextSlide, delay);
        });
        onUnmounted(() => {
            currentBannerSlide.value = 0;
            if (interval.value) {
                clearInterval(interval.value);
            }
        });
        return {
            images,
            eleId,
            currentBannerSlide,
        };
    },
});
