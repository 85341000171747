var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineComponent, onMounted, ref } from '@vue/runtime-core';
import { useRoute, useRouter } from 'vue-router';
export default defineComponent({
    name: 'Gallery',
    setup() {
        const route = useRoute();
        const router = useRouter();
        const id = route.params.id;
        const title = ref('');
        const imageList = ref([]);
        const currentIndex = ref(0);
        const currentImgEle = ref(null);
        const showOverlay = ref(false);
        const next = () => {
            currentIndex.value = currentIndex.value + 1;
            if (currentIndex.value > imageList.value.length - 1) {
                currentIndex.value = 0;
            }
            showPicture(currentIndex.value);
        };
        const prev = () => {
            currentIndex.value = currentIndex.value - 1;
            if (currentIndex.value < 0) {
                currentIndex.value = imageList.value.length - 1;
            }
            showPicture(currentIndex.value);
        };
        const showPicture = (index) => {
            const img = imageList.value[index];
            if (!img)
                return;
            // @ts-ignore
            const imgDivEle = currentImgEle.value.querySelector('.image');
            // @ts-ignore
            const imgEle = currentImgEle.value.querySelector('img');
            // @ts-ignore
            imgEle.src = img.uri;
            // @ts-ignore
            imgDivEle.style.backgroundImage = `url(${img.uri})`;
            // @ts-ignore
            imgEle.addEventListener('load', () => {
                // @ts-ignore
                const width = imgEle.naturalWidth;
                // @ts-ignore
                const height = imgEle.naturalHeight;
                if (width >= height) {
                    if (currentImgEle.value) {
                        currentImgEle.value.classList.add('horizontal');
                        currentImgEle.value.classList.remove('vertical');
                        // currentImgEle.value.classList.add('vertical');
                        // currentImgEle.value.classList.remove('horizontal');
                    }
                }
                else {
                    if (currentImgEle.value) {
                        currentImgEle.value.classList.add('vertical');
                        currentImgEle.value.classList.remove('horizontal');
                    }
                }
                showOverlay.value = true;
            });
        };
        onMounted(() => __awaiter(this, void 0, void 0, function* () {
            const req = yield fetch(process.env.API + `/v1/galleries/gallery/${id}/`);
            const res = yield req.json();
            if (res.id === null)
                router.push('/galleries');
            title.value = res.title;
            imageList.value = res.images.collection.map((image) => {
                return { uri: '/images/galleries/images/' + image.uri };
            });
        }));
        return {
            title,
            imageList,
            currentImgEle,
            showOverlay,
            showPicture,
            next,
            prev,
        };
    },
});
