var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineComponent, onMounted, ref } from '@vue/runtime-core';
export default defineComponent({
    name: 'PhotoGallery',
    setup() {
        const list = ref(null);
        onMounted(() => __awaiter(this, void 0, void 0, function* () {
            const req = yield fetch(process.env.API + '/v1/galleries/');
            const res = yield req.json();
            // list.value = res.collection;
            const keys = Object.keys(res.collection);
            keys.reverse();
            list.value = keys.map(key => {
                let obj = {};
                // @ts-ignore
                obj = { year: key, items: res.collection[key] };
                return obj;
            });
        }));
        const goToYear = (year) => {
            const el = document.getElementById(`year_${year}`);
            if (el) {
                el.scrollIntoView({ behavior: 'smooth' });
            }
        };
        return {
            list,
            goToYear,
        };
    },
});
