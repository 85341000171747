import { defineComponent, ref, watch } from '@vue/runtime-core';
export default defineComponent({
    name: 'SportList',
    props: {
        sports: {
            type: Array,
            default: () => [],
        },
    },
    setup(props) {
        const list = ref(props.sports);
        watch(() => props.sports, newValue => {
            list.value = newValue;
        });
        return {
            list,
        };
    },
});
