var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
export default defineComponent({
    name: 'About',
    setup() {
        const subpages = ['identity', 'history', 'mission', 'job-offert', 'staff'];
        const selection = ref('identity');
        const route = useRoute();
        const router = useRouter();
        const staff = ref(null);
        onMounted(() => __awaiter(this, void 0, void 0, function* () {
            const { subpage } = route.params;
            if (subpages.includes(subpage)) {
                selection.value = subpage;
            }
            else {
                router.push({ name: 'About', params: { subpage: 'identity' } });
            }
            const req = yield fetch(process.env.API + '/v1/staff/');
            const res = yield req.json();
            staff.value = res.collection;
        }));
        const changeSubseccion = (subseccion) => {
            selection.value = subseccion;
            router.push({ name: 'About', params: { subpage: subseccion } });
        };
        return {
            selection,
            changeSubseccion,
            staff,
        };
    },
});
