var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ref } from 'vue';
class IndexBannerCollection {
    constructor() {
        this.count = ref(0);
        this.items = ref([]);
        this._url = process.env.API + '/v1/images/index/';
    }
    getImages() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const bannerImagesReq = yield fetch(this._url);
                const bannerImagesRes = yield bannerImagesReq.json();
                this.count.value = bannerImagesRes.count;
                this.items.value = bannerImagesRes.collection;
            }
            catch (error) {
                console.error(error);
            }
        });
    }
}
export default IndexBannerCollection;
