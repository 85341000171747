var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { defineComponent, onMounted, ref } from 'vue';
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router';
import SportsCollection from '@/composables/collections/SportsCollection';
import SportList from '@/components/SportList.vue';
export default defineComponent({
    name: 'News',
    components: {
        SportList,
    },
    setup() {
        // const subpages = ['boys', 'girls', 'clubs_teams'];
        const subpages = ref([]);
        const selection = ref('boys');
        const route = useRoute();
        const router = useRouter();
        const subpage = ref(route.params.subpage);
        const sportsCollection = new SportsCollection();
        const list = ref([]);
        const checkIfExist = () => {
            if (!subpages.value.includes(subpage.value)) {
                router.push({ name: 'Athletics', params: { subpage: 'boys' } });
            }
        };
        onMounted(() => __awaiter(this, void 0, void 0, function* () {
            yield sportsCollection.getSports();
            checkIfExist();
            if (sportsCollection.items.value) {
                subpages.value = Object.keys(sportsCollection.items.value);
            }
            list.value = sportsCollection.getSection(subpage.value);
        }));
        onBeforeRouteUpdate(to => {
            subpage.value = to.params.subpage;
            list.value = sportsCollection.getSection(subpage.value);
        });
        return {
            selection,
            subpages,
            list,
        };
    },
});
